*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #bee3ff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.title {
  padding: 1rem;
  text-align: center;
  background-color: #122533;
  color: #bee3ff;
  border-bottom: 1px solid #bee3ff;
}

.main {
  width: 100%;
  display: grid;
  padding-bottom: 2rem;
  position: relative;
}

.imageList {
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: 1fr;
}

.imageSingle {
  display: block;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}
.imageSingle:hover {
  border: 1px solid #bee3ff;
}

.currentImageDiv {
  display: none;
  border-bottom: 3px solid #122533;
  height: max-content;
  position: sticky;
  top: 0;
  left: 0;
}

.currentImage {
  width: 100%;
  display: block;
  object-fit: cover;
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 0.5rem;
  background-color: #122533;
  color: #bee3ff;
  letter-spacing: 0.1rem;
}

@media screen and (min-width: 768px) {
  .main {
    grid-template-columns: 1fr 250px;
  }

  .currentImageDiv {
    display: block;
  }

  .imageList {
    grid-template-columns: 250px;
  }
}
@media screen and (min-width: 1120px) {
  .main {
    grid-template-columns: 1fr 500px;
  }

  .imageList {
    grid-template-columns: repeat(2, 250px);
  }
}
/*# sourceMappingURL=index.784987ad.css.map */
